(function (angular, undefined) {
    "use strict";
    var resume = angular.module("mikesResume");

    resume.config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {
        $stateProvider
            .state("resume", {
                parent: "mike's",
                url: "/resume",
                data: {
                    pageTitle: "Mike's Resume"
                },
                views: {
                    "": {
                        templateUrl: "app/resume/templates/resume.html",
                        controller: "mikesRes.ctrl",
                        controllerAs: "vm"
                    }
                }
            });
    }]);

})(angular);
